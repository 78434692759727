import axios from 'axios'
import { endpointDownloadPhotos, endpointDownloadWishes, endpointDownloadPromptpay } from './enpoints'

const download = () => {

  const downloadWishes = async (eventId: string, email: string) => {
    const fullEndpoint = endpointDownloadWishes.cloud
    const { data } = await axios.post(fullEndpoint, {
      eventId: eventId,
      sendToEmail: email
    })
    return data
  }

  const downloadPromptpay = async (eventId: string, email: string) => {
    const fullEndpoint = endpointDownloadPromptpay.cloud
    const { data } = await axios.post(fullEndpoint, {
      eventId: eventId,
      sendToEmail: email
    })
    return data
  }

  const downloadPromptpayFree = async (eventId: string, promptpayAccount: string) => {
    const fullEndpoint = "https://plankton-app-u9jct.ondigitalocean.app/print-qr-from-line-liff"
    const { data } = await axios.post(fullEndpoint, {
      eventId: eventId,
      promptpayNo: promptpayAccount
    })
    return data?.url || null
  }

  const downloadPhotos = async (eventId: string, email: string) => {
    const fullEndpoint = endpointDownloadPhotos.cloud
    const { data } = await axios.post(fullEndpoint, {
      eventId: eventId,
      sendToEmail: email
    })
    return data
  }

  return {
    downloadWishes,
    downloadPromptpay,
    downloadPromptpayFree,
    downloadPhotos
  }
}

export default download